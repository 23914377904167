import React, { useState } from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import { useQueryParam } from '@/hooks/useQueryParam';
import RoutePaths from '@/utils/routes';
import { navigate } from 'gatsby';
import LandingService from '@/services/landing';
import { ILandingPageVar } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import NotFoundPage from '../404';

const OverviewLoadable = Loadable({
  loader: () => import('@/sections/Overview'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const GenericLoadable = Loadable({
  loader: () => import('@/sections/Generic'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const MyReportLoadable = Loadable({
  loader: () => import('@/sections/MyReport'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const arrRoute = [RoutePaths.MY_REPORT_DYNAMIC, RoutePaths.AGENT_REPORT_DYNAMIC];
const OverviewPage: React.FC<{ dynamicUrl: string }> = ({ dynamicUrl }) => {
  const [dataUrlVar, setDataUrlVar] = useState({} as ILandingPageVar);
  const { ssid } = useQueryParam<{ ssid: string }>();
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    fetchListLanding();
  }, []);

  const fetchListLanding = async () => {
    if (arrRoute.includes(dynamicUrl)) return;
    try {
      const resVarDetail: ILandingPageVar = await LandingService.retrieveLandingPageVariable(dynamicUrl);
      setDataUrlVar(resVarDetail);
    } catch (error) {
      navigate(RoutePaths.NOT_FOUND);
    } finally {
      setIsLoading(false);
    }
  };

  if (dataUrlVar?.id)
    return (
      <React.Fragment>
        <HeaderMetadata title={dataUrlVar?.name} />
        <GenericLoadable dataVar={dataUrlVar} dynamicUrl={dynamicUrl} />
      </React.Fragment>
    );

  if (dynamicUrl === RoutePaths.MY_REPORT_DYNAMIC || dynamicUrl === RoutePaths.AGENT_REPORT_DYNAMIC) {
    return (
      <React.Fragment>
        <HeaderMetadata title={dynamicUrl === RoutePaths.MY_REPORT_DYNAMIC ? 'My Report' : 'Overview'} />
        <PrivateProvider>
          {dynamicUrl === RoutePaths.MY_REPORT_DYNAMIC && !ssid ? <MyReportLoadable /> : <OverviewLoadable />}
        </PrivateProvider>
      </React.Fragment>
    );
  } else if (isLoading) {
    return <LoadingPage isLoading={true} />;
  } else {
    return <NotFoundPage />;
  }
};

export default OverviewPage;
